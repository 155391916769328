import moment from 'moment';
import {REGISTERED_TEAM_STATUS_OPTIONS, TIME_FORMATS} from "constants/index";
import jwt from 'jsonwebtoken';
import {LOCAL_STORAGE_TAGS} from "constants/index";
import _ from 'lodash';

export const getToken = () => {
    return localStorage.getItem(LOCAL_STORAGE_TAGS.BVR_USER_TOKEN);
};

export const getUsername = () => {
    const parseJwt = jwt.decode(getToken());
    return _.get(parseJwt, 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name', []);
};
export const getUserName = () => {
    const parseJwt = jwt.decode(getToken());
    return _.get(parseJwt, 'Name', []);
};

export const checkPermission = (permission, type) => {
    const parseJwt = jwt.decode(getToken());
    const permissions = _.get(parseJwt, 'Permission', []);
    const index = permissions.indexOf(`${type}_${permission}`);
    return index !== -1;
};

export const checkRole = (role) => {
    const parseJwt = jwt.decode(getToken());
    const jwtRoles = _.get(parseJwt, 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role', []);
    const index = jwtRoles.indexOf(role);
    return index !== -1;
};

export const getAge = (birthdate) => {
    return moment().diff(moment(birthdate), 'years');
};

export const getRemainingTime = (start) => {
    let remainingTime = {};
    
    let days = moment(start).diff(moment(), 'days');
    
    let x = moment().add(days, 'days');
    let hours = moment(start).diff(moment(x), 'hours');
    
    let y = moment(x).add(hours, 'hours');
    let minutes = moment(start).diff(moment(y), 'minutes');
    
    let z = moment(y).add(minutes, 'minutes');
    
    remainingTime.days = days;
    remainingTime.hours = hours;
    remainingTime.minutes = minutes;
    remainingTime.seconds = moment(start).diff(moment(z), 'seconds');
    
    return remainingTime;
};

export const getFormattedPeriod = (start, end) => {
    if (start && end) {
        let startDay = moment(start).format(TIME_FORMATS.DAY);
        let endDay = moment(end).format(TIME_FORMATS.DAY);
        let year = moment(start).format(TIME_FORMATS.YEAR);
        
        let startMonth;
        let endMonth;
        if (moment(start).month() === moment(end).month()) {
            startMonth = moment(start).format(TIME_FORMATS.SHORT_MONTH);
            return `${startDay} - ${endDay} ${startMonth} ${year}`;
        } else {
            startMonth = moment(start).format(TIME_FORMATS.SHORT_MONTH);
            endMonth = moment(end).format(TIME_FORMATS.SHORT_MONTH);
            return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${year}`;
        }
    } else if (start && !end) {
        let startDay = moment(start).format(TIME_FORMATS.DAY);
        let year = moment(start).format(TIME_FORMATS.YEAR);
        let startMonth = moment(start).format(TIME_FORMATS.SHORT_MONTH);
        return `${startDay} ${startMonth} ${year} - /`;
    } else if (!start && end) {
        let endDay = moment(end).format(TIME_FORMATS.DAY);
        let year = moment(end).format(TIME_FORMATS.YEAR);
        let endMonth = moment(end).format(TIME_FORMATS.SHORT_MONTH);
        return `/ - ${endDay} ${endMonth} ${year}`;
    }
    
};

export const getFormattedURL = (url) => {
    if (url) {
        let result = url.substring(0, 5);
        if (result === 'http') {
            return url;
        }
        return `http://${url}`;
    }
    return '';
    
};

export const getSplitTitle = (text) => {
    let hasDash;
    if (text) {
        hasDash = text.search("-") !== -1;
        if (hasDash) {
            let title = text.split(" - ")[0];
            let subtitle = text.split(" - ")[1];
            return {
                title: title,
                subtitle: subtitle
            };
        } else return {title: text, subtitle: null};
    } else return {title: null};
    
};

export const getCollapsedText = (text, length = 20) => {
    let minCollapsingLength = length;
    if (text && text.length > minCollapsingLength)
        return `${text.substring(0, minCollapsingLength)} ... `;
    return text;
};

export const getTeamsOptions = (list = []) => list.map(item => (
    {
        id: item.teamId,
        name: item.teamName
    } || []
));

export const getUsersOptions = (list = []) => list.map(item => (
    {
        id: item.userId,
        name: item.username
    } || []
));


// export const getImageBlob = (b64Data) => {
//     const splitDataURI = b64Data.split(',')
//     const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
//     const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
//
//     const ia = new Uint8Array(byteString.length)
//     for (let i = 0; i < byteString.length; i++)
//         ia[i] = byteString.charCodeAt(i)
//
//     return new Blob([ia], { type: mimeString });
//
// };
export function getImageBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || 'image/png';
    sliceSize = sliceSize || 512;
    
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);
        
        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        
        let byteArray = new Uint8Array(byteNumbers);
        
        byteArrays.push(byteArray);
    }
    
    let blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

export const getYearsOptions = () => {
    let yearsList = [];
    let minYear = 2020;
    let maxYear = moment().year() + 1;
    
    for (let i = minYear; i <= maxYear; i++) {
        yearsList.push({id: i, name: i});
    }
    return yearsList;
};

export const getTeamStatusName = status => {
    let name = null;
    let statusDetails = REGISTERED_TEAM_STATUS_OPTIONS.find(sts => status === sts.id);
    if (statusDetails) {
        name = statusDetails.name;
    }
    return name;
};

export function getCalculatedNumberOfTeams(allowed, registrationDetails) {
    const registeredAndConfirmed = registrationDetails.Registered + registrationDetails.Confirmed;
    const isFullDraw = allowed - registeredAndConfirmed <= 0;
    const availableSpotsOnDraw = isFullDraw ? 0 : allowed - registeredAndConfirmed;
    const pendingTeams = registrationDetails.Pending;
    const withdrawnTeams = registrationDetails.Cancelled;
    
    return {
        allowed,
        isFullDraw,
        availableSpotsOnDraw,
        pendingTeams,
        withdrawnTeams
    };
}

export const getCategoryOptions  =()=> [{id:1,name: 'Men'},{id:2,name:'Women'}];