import React, {Fragment} from 'react';
import {Image, Col, Spinner, Button} from 'react-bootstrap';
import {CalendarWeekFill, GeoAlt, GeoAltFill, Calendar4Week, ArrowRightCircleFill, Check} from 'react-bootstrap-icons';
import {Loader} from "components/Loader";
import {connect} from "react-redux";
import moment from 'moment';
import * as AppActions from 'actions';
import {getFormattedPeriod} from 'utils/index';
import {PATH_URLS, SIDEBAR_ITEM_KEYS} from 'constants/index';
import NumberOfTeamsDetails from 'pages/home/NumberOfTeamsDetails';
import RegistrationStatus from 'pages/home/RegistrationStatus';

const getTournamentsByYear = (tournamentsList) => {
    let tournaments = {};
    for (let i = 0; i < tournamentsList.length; i++) {
        let tournamentYear = moment(tournamentsList[i].startDate).year();
        if (tournaments[tournamentYear]) {
            tournaments[tournamentYear].push(tournamentsList[i]);
        } else {
            tournaments[tournamentYear] = [tournamentsList[i]];
        }
    }
    return tournaments;
};

class HomePage extends React.Component {
    
    render() {
        const {upcomingTournament, tournamentsList, loadingMainEvent, loading} = this.props;
        
        const tournamentsByYear = getTournamentsByYear(tournamentsList);
        
        const getIsActiveClassName = (start, end) => {
            if (moment().isBefore(moment(start))) {
                return 'home-event-future';
            } else if (moment().isAfter(moment(end))) {
                return 'home-event-past';
            }
            return 'home-event-ongoing';
            
        };
        if (loadingMainEvent || loading) {
            return (
                <Col className={'home-page-container'}>
                    <div className={'align-center'}>
                        <Loader/>
                    </div>
                </Col>
            );
        }
        return (
            <Col className={'home-page-container'}>
                {upcomingTournament && upcomingTournament.length > 0 &&
                 <div className={'home-upcoming-event-container'}>
                     <div className={'upcoming-event-details-container'}>
                         <div className={'upcoming-label'}>
                             {moment(upcomingTournament[0].startDate).isAfter(moment())
                              ? 'Upcoming Event'
                              : 'Ongoing Event'}
                         </div>
                         <div>
                             <div className={'upcoming-event-title'}>{upcomingTournament[0].name}</div>
                             <div className={'display-flex align-center'}>
                                 <GeoAlt size={14}/>&nbsp;&nbsp;
                                 {upcomingTournament[0].cityName}&nbsp;&nbsp;|&nbsp;&nbsp;
                                 <Calendar4Week size={14}/>&nbsp;&nbsp;
                                 {getFormattedPeriod(
                                     upcomingTournament[0].startDate,
                                     upcomingTournament[0].endDate
                                 )}
                             </div>
                         </div>
                         <a href={`${PATH_URLS.EVENT}/${upcomingTournament[0].id}${SIDEBAR_ITEM_KEYS.DETAILS}`}>
                             <div className={'upcoming-redirect'}>
                                 View Event Details&nbsp;&nbsp;<ArrowRightCircleFill size={14}/>
                             </div>
                         </a>
                     </div>
                     <div
                         className={'upcoming-event-image-container'}
                         style={{backgroundImage: `url(data:image/jpeg;base64,${upcomingTournament[0].image})`}}
                     >&nbsp;</div>
                 </div>
                }
    
                {Object.keys(tournamentsByYear) && Object.keys(tournamentsByYear).length > 0 &&
                 <Fragment>
                     {Object.keys(tournamentsByYear).sort().reverse().map(year => {
                         return (
                             <Fragment key={year}>
                                 <div className={'home-year-header'}>{year}</div>
                                 <div className={'home-events-grid'}>
                                     {tournamentsByYear[year].sort((a, b) => moment(a.startDate).diff(moment(b.startDate))).map(tournament => {
                                         let isActiveClassName = getIsActiveClassName(tournament.startDate, tournament.endDate);
                                         return (
                                             <a href={`${PATH_URLS.EVENT}/${tournament.id}${SIDEBAR_ITEM_KEYS.DETAILS}`}>
                                                 <div key={tournament.id} className={`home-event-card ${isActiveClassName}`}>
                                                     <div
                                                         className={'home-event-photo'}
                                                         style={{
                                                             backgroundImage: tournament.smallImage
                                                                              ? `url(data:image/jpeg;base64,${tournament.smallImage})`
                                                                              : `url(${'beach_volleyball_event_img.jpg'})`
                                                         }}>
                                                     </div>
                                                     <div className={`home-event-details`}>
                                                         <div className={'home-event-detail'}>
                                                             <CalendarWeekFill size={12}/>&nbsp;&nbsp;
                                                             {getFormattedPeriod(tournament.startDate, tournament.endDate)}
                                                         </div>
                                                         <div className={'home-event-title'}>{tournament.name}</div>
                                                         <div className={'home-event-detail'}>
                                                             <GeoAltFill size={12}/>&nbsp;&nbsp;
                                                             {tournament.cityName}
                                                         </div>
                                                     </div>
                                                     <RegistrationStatus tournament={tournament}/>
                                                     <div className={'home-event-footer'}>
                                                         <NumberOfTeamsDetails tournament={tournament}/>
                                                     </div>
                                                 </div>
                                             </a>
                                         );
                                     })}
                                 </div>
                             </Fragment>
                         );
                     })}
                 </Fragment>
                }
            </Col>
        );
    }
    
    async componentDidMount() {
        this.props.getMainEvent();
        this.props.getTournaments({
            includeSmallImage: true
        });
    }
}

const mapStateToProps = (state) => ({
    tournamentsList: state.appReducer.tournamentsList,
    loading: state.appReducer.loading,
    loadingMainEvent: state.appReducer.loadingMainEvent,
    upcomingTournament: state.appReducer.upcomingTournament
    
});

const mapDispatchToProps = (dispatch) => ({
    getMainEvent: () => dispatch(AppActions.getUpcomingTournament()),
    getTournaments: (params) => dispatch(AppActions.getTournaments(params))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(HomePage);
export default withConnect;