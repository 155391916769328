import React from 'react';
import {Formik, Field, Form} from 'formik';
import {Col, Row} from 'react-bootstrap';
import {CustomDropdownComponent, CustomInputComponent} from 'components/RenderInput';
import {GENDER_CATEGORY_OPTIONS, REGISTERED_TEAM_STATUS_OPTIONS} from 'constants/index';
import {SpinnerButton, SubmitButton} from 'components/Buttons';
import {CheckCircleFill} from 'react-bootstrap-icons';

const validate = (values, edit) => {
    const errors = {};

    if (!values.teamName) {
        errors.teamName = 'Required';
    }
    if (!values.email) {
        errors.email = 'Required';
    }
    if (!values.tournamentId) {
        errors.tournamentId = 'Required';
    }
    if (!values.category) {
        errors.category = 'Required';
    }
    if (!values.players?.[0]?.playerId) {
        errors['players[0].playerId'] = 'Required';
    }
    if (!values.players?.[1]?.playerId) {
        errors['players[1].playerId'] = 'Required';
    }
    if (!values.players?.[0]?.sizeId) {
        errors['players[0].sizeId'] = 'Required';
    }
    if (!values.players?.[1]?.sizeId) {
        errors['players[1].sizeId'] = 'Required';
    }
    if (!values.status && edit) {
        errors.status = 'Required';
    }
    
    if (values.players?.[0]?.playerId && values.players?.[1]?.playerId && values.players?.[0]?.playerId === values.players?.[1]?.playerId) {
        errors['players[1].playerId'] = 'Please select different players';
    }
    
    return errors;
};

function TeamsForm({
    onSubmit,
    initialValues,
    tournamentsList,
    playersList,
    edit,
    saveLoading,
    onChangeTeamCategory,
    sizesOptions
}) {
    const isPlayersDisabled = (formProps) => {
        return (!edit && !formProps.values.category);
    };
    const noPlayersMessage = (formProps) => {

        if (!formProps.values.category) {
            return 'Please select a category first';
        } else if (playersList && playersList.length < 1) {
            return 'There are no more other players on BVR app that haven\'t already been registered.';
        }

    };
    
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={(values) => validate(values, edit)}
            validateOnMount={true}
            validateOnBlur={true}
        >
            {formProps => {
                return (
                    <Form>
                        <Row>
                            <Col xs={12} sm={6} className={'padding-small'}>
                                <Field
                                    name="tournamentId"
                                    component={CustomDropdownComponent}
                                    options={tournamentsList}
                                    label={'Tournament'}
                                    disabled
                                    noEmptyField
                                />
                            </Col>
                             <Col xs={12} sm={6} className={'padding-small'}>
                                 <Field
                                     name="status"
                                     component={CustomDropdownComponent}
                                     options={REGISTERED_TEAM_STATUS_OPTIONS}
                                     label={'Status'}
                                     noEmptyField={!!edit}
                                 />
                             </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} className={'padding-small'}>
                                <Field name="teamName" component={CustomInputComponent} label={'Team Name'}/>
                            </Col>
                            <Col xs={12} sm={6} className={'padding-small'}>
                                <Field
                                    name="category"
                                    component={CustomDropdownComponent}
                                    options={GENDER_CATEGORY_OPTIONS}
                                    label={'Category'}
                                    disabled={edit}
                                    onChangeCallback={(v) => onChangeTeamCategory(v, formProps)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} className={'padding-small'}>
                                <Field
                                    name="players[0].playerId"
                                    component={CustomDropdownComponent}
                                    options={playersList.map(player => ({...player, id: player.playerId}))}
                                    label={'Player #1'}
                                    disabled={isPlayersDisabled(formProps)}
                                />
                            
                            </Col>
                            <Col xs={12} sm={6} className={'padding-small'}>
                                <Field
                                    name="players[0].sizeId"
                                    component={CustomDropdownComponent}
                                    options={sizesOptions}
                                    label={'T-shirt Size'}
                                />
                            </Col>
                            <Col xs={12} sm={6} className={'padding-small'}>
                                <Field
                                    name="players[1].playerId"
                                    component={CustomDropdownComponent}
                                    options={playersList.map(player => ({...player, id: player.playerId}))}
                                    label={'Player #2'}
                                    disabled={isPlayersDisabled(formProps)}
                                />
                            </Col>
                            <Col xs={12} sm={6} className={'padding-small'}>
                                <Field
                                    name="players[1].sizeId"
                                    component={CustomDropdownComponent}
                                    options={sizesOptions}
                                    label={'T-shirt Size'}
                                />
                            </Col>
                            <Col xs={12} className={'font-small color-warning'}>
                                {noPlayersMessage(formProps)}
                            </Col>
                            
                            <Col xs={12} sm={8} className={'padding-small'}>
                                <Field name="email" component={CustomInputComponent} label={'E-mail'}/>
                            </Col>
                            <Col xs={12} className={'text-right padding-small'}>
                                {saveLoading
                                 ? <SpinnerButton label={'Saving'}/>
                                 : <SubmitButton label={'Save '} icon={<CheckCircleFill size={12}/>} disabled={!formProps.isValid}/>
                                }
                            </Col>
                        </Row>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default TeamsForm;