import React, {Component, Fragment} from 'react';
import {BreadcrumbComponent} from 'components/Breadcrumb';
import {PATH_URLS, EVENT_CATEGORY, REGISTERED_TEAM_STATUS} from "constants/index";
import {AddButton} from "components/Buttons";
import {TableComponent} from "components/Table";
import {ModalComponent, ConfirmModal} from "components/Modal";
import {connect} from "react-redux";
import * as AppActions from "actions";
import GamesForm from 'pages/admin_pages/games/form';
import GameScoreForm from 'pages/admin_pages/games/gameScoreForm';
import GamesFilterForm from 'pages/admin_pages/games/filterForm';
import moment from 'moment';
import {CircleFill, Grid3x2Gap} from 'react-bootstrap-icons';
import {getTeamsOptions} from 'utils/index';
import {gameScoreModel} from 'pages/admin_pages/games/utils';
import {resetTeams} from 'actions';
import {Button} from "react-bootstrap";

class Admin_Games extends Component {
    state = {
        openForm: false,
        openConfirm: false,
        openScoreForm: false,
        gameItem: {},
        editItem: {},
        deleteItem: {},
        filter: {
            category: 0
        },
        filterFormTeamsList: [],
        confirmGenerate: false,
        selectedTournament: null
    };

    onOpenForm = async (item) => {
        if (item) {
            await this.props.getTeams({
                tournamentId: this.state.filter.tournamentId,
                categoryId: item.category,
                includeTeamsFromSystem: true,
                status: REGISTERED_TEAM_STATUS.CONFIRMED
            });
        } else {
            if (this.state.filter.category && this.state.filter.category !== 0) {
                this.props.getTeams({
                    tournamentId: this.state.filter.tournamentId,
                    categoryId: this.state.filter.category,
                    includeTeamsFromSystem: true,
                    status: REGISTERED_TEAM_STATUS.CONFIRMED
                });
            } else {
                this.props.resetTeams();
            }

        }
        this.setState({
            openForm: true,
            editItem: item
        });
    };

    closeForm = () => {
        this.props.resetTeams();
        this.setState({
            openForm: false,
            editItem: {},
            openScoreForm: false,
            gameItem: {}
        });
    };

    handleSelectCategory = (value) => {
        this.props.getTeams({
            tournamentId: this.state.filter.tournamentId,
            categoryId: +value,
            includeTeamsFromSystem: true,
            status: REGISTERED_TEAM_STATUS.CONFIRMED
        });
    }

    getFilterParams = (values) => {
        let category = values.category;
        if (+values.category === 0) {
            category = null;
        }

        return {
            scheduleId: values.scheduleId,
            teamId: values.teamId,
            tournamentId: values.tournamentId,
            categoryId: category
        };
    };

    filterData = async (values) => {
        let params = this.getFilterParams(values);

        if (values.tournamentId !== this.state.filter.tournamentId) {
            const teams = await this.props.getTeams({
                tournamentId: values.tournamentId,
                status: REGISTERED_TEAM_STATUS.CONFIRMED
            });
            if (teams) {
                this.setState({
                    filterFormTeamsList: getTeamsOptions(teams)
                })
            }
            await this.props.getSchedules({tournamentId: values.tournamentId});
        }
        if (values.category !== this.state.filter.category) {
            const teams = await this.props.getTeams({
                tournamentId: values.tournamentId,
                categoryId: +values.category === 0 ? null : values.category,
                status: REGISTERED_TEAM_STATUS.CONFIRMED
            });
            if (teams) {
                this.setState({
                    filterFormTeamsList: getTeamsOptions(teams)
                })
            }
        }

        this.props.getGames(params);
        this.setState({
            filter: values
        });
    };

    saveGame = async (values) => {
        if (this.state.editItem) {
            await this.props.editGame(values);
        } else {
            await this.props.addGame(values);
        }
        let params = this.getFilterParams(this.state.filter);
        this.props.getGames(params);
        this.closeForm();
    };

    onDeleteItem = (item) => {
        this.setState({
            openConfirm: true,
            deleteItem: item
        });
    };

    onConfirmDelete = async () => {
        await this.props.deleteGame(this.state.deleteItem);
        let params = this.getFilterParams(this.state.filter);
        this.props.getGames(params);
        this.closeConfirm();
    };
    closeConfirm = () => {
        this.setState({
            openConfirm: false,
            deleteItem: {}
        });
    };

    onOpenEditFinalScoreForm = (game) => {
        this.setState({
            openScoreForm: true,
            gameItem: game
        });
    };

    saveGameScore = async (values) => {
        if (this.state.gameItem && this.state.gameItem.gameResultsDto) {
            await this.props.editGameResults(values);
        } else {
            await this.props.addGameResults(values);
        }
        let params = this.getFilterParams(this.state.filter);
        this.props.getGames(params);
        this.closeForm();
    };

    showConfirmGenerateGames = () => {
        this.setState({confirmGenerate: true})
    }
    closeConfirmGenerateGames = () => {
        this.setState({confirmGenerate: false})
    }

    onConfirmGenerate = async () => {
        if (this.state.selectedTournament) {
            await this.props.generateGamesForTournament(this.state.selectedTournament.id);
        }
        let params = this.getFilterParams(this.state.filter);
        this.props.getGames(params);
        this.setState({confirmGenerate: false});
    }

    setSelectedTournament = (item) => {
        this.setState({selectedTournament: item})
    }


    render() {
        const {gamesList, schedulesList, saveLoading, loading, teamsList, tournamentsList} = this.props;
        const {
            openForm,
            editItem,
            openConfirm,
            deleteItem,
            filter,
            openScoreForm,
            gameItem,
            filterFormTeamsList,
            confirmGenerate,
            selectedTournament
        } = this.state;
        const showGenerateBtn = selectedTournament &&
            moment(selectedTournament.startDate).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD') &&
            moment(selectedTournament.endDateRegister).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD');

        const otherButtons = [
            {
                action: (item) => {
                    this.onOpenEditFinalScoreForm(item);
                },
                label: 'Update Final Score',
                icon: <Grid3x2Gap/>
            }
        ];
        return (
            <div className={'page-container'}>
                <BreadcrumbComponent links={[
                    {path: PATH_URLS.ADMIN_PROFILE, name: 'Admin Dashboard'},
                    {path: PATH_URLS.ADMIN_GAMES, name: 'Games'}
                ]}/>
                <div className={'admin-page-container'}>
                    <div className={'admin-page-title'}>
                        <div className={'display-flex align-center'}>Games</div>
                        <div>
                            {showGenerateBtn &&
                                <Button className={'submit-btn'} type={'button'}
                                        onClick={() => this.showConfirmGenerateGames()}>
                                    Generate Games
                                </Button>
                            }
                            <AddButton onClick={() => this.onOpenForm(null)}/>
                        </div>
                    </div>
                    {tournamentsList && tournamentsList.length > 0 &&
                        <Fragment>
                            <div className={'filter-container'}>
                                <GamesFilterForm
                                    tournamentsList={tournamentsList}
                                    teamsList={filterFormTeamsList}
                                    schedulesList={schedulesList}
                                    onSubmit={this.filterData}
                                    initialValues={{
                                        ...filter,
                                        tournamentId: filter.tournamentId || tournamentsList[0].id
                                    }}
                                    setSelectedTournament={this.setSelectedTournament}
                                />

                            </div>

                            <TableComponent
                                data={gamesList}
                                header={header}
                                editable={true}
                                removable={true}
                                onEditItem={this.onOpenForm}
                                onDeleteItem={this.onDeleteItem}
                                loading={loading}
                                otherButtons={otherButtons}
                            />

                            <ModalComponent
                                show={openForm}
                                onHide={this.closeForm}
                                title={editItem ? 'Update Game' : 'Add New Game'}
                            >
                                <GamesForm
                                    initialValues={editItem ?
                                        {
                                            ...editItem,
                                            startDate: moment(editItem.startDate).format('HH:mm')
                                        } : {
                                            ...filter,
                                            tournamentId: filter.tournamentId || tournamentsList[0].id
                                        }}
                                    onSubmit={this.saveGame}
                                    schedulesList={schedulesList}
                                    teamsList={teamsList}
                                    saveLoading={saveLoading}
                                    handleSelectCategory={this.handleSelectCategory}
                                />
                            </ModalComponent>

                            <ConfirmModal
                                show={openConfirm}
                                onHide={this.closeConfirm}
                                title={`Remove Game`}
                                handleConfirm={this.onConfirmDelete}
                                handleClose={this.closeConfirm}
                                content={<Fragment>
                                    <div>Are you sure you want to remove this game:</div>
                                    <div>
                                        {`${deleteItem.scheduleName} | ${moment(deleteItem.startDate).format('HH:mm')} | ${deleteItem.teamOneName} vs. ${deleteItem.teamTwoName}?`}
                                    </div>
                                </Fragment>
                                }
                            />

                            <ModalComponent
                                show={openScoreForm}
                                onHide={this.closeForm}
                                title={'Update Score'}
                            >
                                <GameScoreForm
                                    initialValues={gameItem.gameResultsDto ? gameItem.gameResultsDto : gameScoreModel(gameItem)}
                                    game={gameItem}
                                    onSubmit={this.saveGameScore}
                                    saveLoading={saveLoading}
                                />
                            </ModalComponent>
                            <ConfirmModal
                                show={confirmGenerate}
                                onHide={this.closeConfirmGenerateGames}
                                title={`Generate Games`}
                                handleConfirm={this.onConfirmGenerate}
                                handleClose={this.closeConfirmGenerateGames}
                                content={<Fragment>
                                    <div>Are you sure you want to generate games?</div>
                                    <div>
                                        Any existing games will be erased by this action.
                                    </div>
                                </Fragment>
                                }
                            />
                        </Fragment>
                    }
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.resetGames();
        this.props.resetSchedules();
        this.props.resetTeams();
        this.props.resetTournaments();
    }

    async componentDidMount() {
        const tournaments = await this.props.getTournaments();
        if (tournaments && tournaments.length > 0) {
            await this.setState((prevState) => ({
                filter: {
                    ...prevState.filter,
                    tournamentId: tournaments[0].id
                },
                selectedTournament: tournaments[0]
            }));
            let params = this.getFilterParams(this.state.filter);
            this.props.getGames(params);
            const teams = await this.props.getTeams({
                tournamentId: this.state.filter.tournamentId,
                status: REGISTERED_TEAM_STATUS.CONFIRMED
            });
            if (teams) {
                this.setState({
                    filterFormTeamsList: getTeamsOptions(teams)
                })
            }
            this.props.getSchedules({tournamentId: this.state.filter.tournamentId});
        }
    }

}

const mapStateToProps = (state) => ({
    gamesList: state.appReducer.gamesList,
    schedulesList: state.appReducer.schedulesList,
    teamsList: getTeamsOptions(state.appReducer.teamsList),
    loading: state.appReducer.loading,
    tournamentsList: state.appReducer.managerTournamentsList,
    saveLoading: state.appReducer.saveLoading
});

const mapDispatchToProps = (dispatch) => ({
    getGames: (item) => dispatch(AppActions.getGames(item)),
    resetGames: () => dispatch(AppActions.resetGames()),
    getSchedules: (item) => dispatch(AppActions.getSchedules(item)),
    resetSchedules: () => dispatch(AppActions.resetSchedules()),
    getTeams: (item) => dispatch(AppActions.getTeams(item)),
    resetTeams: () => dispatch(AppActions.resetTeams()),
    addGame: (item) => dispatch(AppActions.addGame(item)),
    editGame: (item) => dispatch(AppActions.editGame(item)),
    editGameResults: (item) => dispatch(AppActions.editGameResults(item)),
    addGameResults: (item) => dispatch(AppActions.addGameResults(item)),
    deleteGame: (item) => dispatch(AppActions.deleteGame(item)),
    getTournaments: () => dispatch(AppActions.getManagerTournaments()),
    resetTournaments: () => dispatch(AppActions.resetManagerTournaments()),
    generateGamesForTournament: (tournamentId) => dispatch(AppActions.generateGamesForTournament(tournamentId))
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(Admin_Games);
export default withConnect;

const header = [
    {
        display: '',
        property: 'category',
        map: ((item, index) => ({
                display: <div key={index}>
                    <CircleFill color={item.category === EVENT_CATEGORY.MALE ? '#002b7f' : '#fcd116'}/>
                </div>
            })
        )
    },
    {display: 'No.', property: 'gameNumber'},
    {
        display: 'Time',
        map: ((item, index) => ({
                display: <Fragment key={index}>
                    <div className={'font-bold'}>{moment(item.startDate).format('HH:mm')}</div>
                    <div className={'font-small'}>{moment(item.startDate).format('DD-MM-YYYY')}</div>
                </Fragment>
            })
        )
    },
    {
        display: 'Team 1',
        map: ((item, index) => ({
                display: <div key={index}>
                    <div className={'font-bold'}>
                        {item.teamOneName}
                    </div>
                    <div className={'team-players-names'}>
                        {item.playerOneTeamOne && `${item.playerOneTeamOne} & ${item.playerTwoTeamOne}`}
                    </div>
                </div>
            })
        ),
    },
    {
        display: 'Team 2',
        map: ((item, index) => ({
                display: <div key={index}>
                    <div className={'font-bold'}>
                        {item.teamTwoName}
                    </div>
                    <div className={'team-players-names'}>
                        {item.playerOneTeamTwo && `${item.playerOneTeamTwo} & ${item.playerTwoTeamTwo}`}
                    </div>
                </div>
            })
        ),
    },
    {
        display: 'Score',
        property: 'finalScore',
        map: ((item, index) => ({
                display: <Fragment key={index}>
                    <div className={'font-bold'}>{item.finalScore || '--:--'}</div>
                    <div className={'font-small'}>
                        {item.gameResultsDto && item.gameResultsDto.setResults.map((set, index) => (
                            <span key={index}>
                                {set.teamOneScore}
                                {' - '}
                                {set.teamTwoScore}
                                {item.gameResultsDto.setResults.length > index + 1 ? ' | ' : null}
                                </span>
                        ))
                        }
                    </div>
                </Fragment>
            })
        )
    }
];