export const TIME_FORMATS = {
    DAY_MONTH_YEAR: 'DD MMM YYYY',
    DAY: 'DD',
    NUMBERED_MONTH: 'MM',
    SHORT_MONTH: 'MMM',
    LONG_MONTH: 'MMMM',
    YEAR: 'YYYY',
    TIME:'HH:MM',
    API_REQUEST: 'YYYY-MM-DD'
};

export const PATH_URLS = {
    HOME: '/',
    ABOUT: '/about',
    CONTACT: '/contact',
    RANKING: '/ranking',
    EVENT: '/event',
    LOGIN: '/login',
    REGISTER: '/register',
    CONFIRM_REGISTER: '/confirm',
    FORGOT_PASSWORD: '/forgot-password',
    CHANGE_PASSWORD: '/change-password',
    USER_PROFILE: '/player-profile',
    ADMIN_PROFILE: '/admin',
    ADMIN_TOURNAMENTS: '/tournaments',
    ADMIN_SCHEDULES: '/schedules',
    ADMIN_GAMES: '/games',
    ADMIN_TEAMS: '/teams',
    ADMIN_AGREEMENT_FILES: '/agreement-files',
    ADMIN_CITIES: '/cities',
    ADMIN_PLAYERS: '/players',
    ADMIN_PLAYERS_HISTORY: '/players-history',
    ADMIN_SPONSORS: '/sponsors',
    ADMIN_REGISTER: '/register',
    ADMIN_TSHIRT_SIZES: '/t-shirt-sizes',
    EVENT_DETAILS: '/details',
    EVENT_REGISTER: '/register',
    EVENT_TEAMS: '/teams',
    EVENT_SPONSORS: '/sponsors',
    EVENT_CONTACT: '/contact',
    EVENT_GAMES: '/games',
    ADMIN_TOURNAMENT_RANKINGS: '/rankings',
    ADMIN_REPORTS: '/reports',
    ADMIN_USERS: '/users',
};

export const SIDEBAR_ITEM_KEYS = {
    DETAILS: '/details',
    REGISTER: '/register',
    PLAYERS: '/players',
    // SPONSORS: 'sponsors',
    CONTACT: '/contact',
    TEAMS: '/teams',
    GAMES: '/games',
    DOCUMENTS: '/documents',
    RANKING: '/ranking',
};

export const SIDEBAR_ITEM_DETAILS = [
    {key: SIDEBAR_ITEM_KEYS.DETAILS, title: 'Event Details'},
    {key: SIDEBAR_ITEM_KEYS.REGISTER, title: 'Register'},
    {key: SIDEBAR_ITEM_KEYS.TEAMS, title: 'Teams'},
    {key: SIDEBAR_ITEM_KEYS.GAMES, title: 'Games'},
    {key: SIDEBAR_ITEM_KEYS.RANKING, title: 'Rankings'},
    // {key: SIDEBAR_ITEM_KEYS.SPONSORS, title: 'Sponsors'},
    {key: SIDEBAR_ITEM_KEYS.DOCUMENTS, title: 'Files'},
    {key: SIDEBAR_ITEM_KEYS.CONTACT, title: 'Contact'},
];

export const APIs = {
    SIZE: '/sizes',
    CITY: '/cities',
    TOURNAMENT: '/tournaments',
    MANAGER_TOURNAMENT: '/tournaments/managerTournaments',
    UPCOMING_TOURNAMENT: '/upcomingTournament',
    REGISTER: '/tournamentRegister',
    PLAYERS: '/players',
    CONFIRM_REGISTER: '/players/confirmRegister',
    TEAMS: '/teams',
    SCHEDULE: '/schedules',
    SPONSORS: '/sponsors',
    GAMES: '/games',
    GAME_RESULTS: '/gameResults',
    USER: '/user',
    USERS: '/users',
    DOCUMENTS: '/documents',
    RANK: '/rank',
    USER_PROFILE: '/userprofile',
    PLAYER_HISTORY: '/PlayerHistories',
    TOURNAMENT_RANKINGS: '/tournamentRankings',
    REPORT: '/reports',
    REPORT_TEAMS_DETAILS: '/reports/tournamentTeamDetails',
    REPORT_GAMES_TIMETABLE: '/reports/TournamentSchedule',
    REPORT_TEAMS_FINAL_RANKING: '/reports/TournamentRanking',
};

export const SCREEN_WIDTH_SIZE = {
    XS: 575,
    SM: 767,
    MD: 991,
    LG: 1199,
};
export const EVENT_CATEGORY = {
    MALE: 1,
    FEMALE: 2
};
export const GENDER_CATEGORY_OPTIONS = [
    {id: 1, name: 'Men'},
    {id: 2, name: 'Women'}
];

export const REGISTERED_TEAM_STATUS = {
    REGISTERED: 1,
    PENDING: 2,
    CONFIRMED: 3,
    CANCELLED: 4,
};
export const REGISTERED_TEAM_STATUS_OPTIONS = [
    {id: REGISTERED_TEAM_STATUS.REGISTERED, name: 'Registered'},
    {id: REGISTERED_TEAM_STATUS.PENDING, name: 'Pending'},
    {id: REGISTERED_TEAM_STATUS.CONFIRMED, name: 'Confirmed'},
    {id: REGISTERED_TEAM_STATUS.CANCELLED, name: 'Withdrawn'}
];

export const ACTIONS_TYPES = {
    ADD_NOTIFICATION: 'ADD_NOTIFICATION',
    REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
    LOGIN_INIT: 'LOGIN_INIT',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    RESET_PASSWORD_INIT: 'RESET_PASSWORD_INIT',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
    FORGOT_PASSWORD_INIT: 'FORGOT_PASSWORD_INIT',
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
    REGISTER_PLAYER_INIT: 'REGISTER_PLAYER_INIT',
    REGISTER_PLAYER_SUCCESS: 'REGISTER_PLAYER_SUCCESS',
    REGISTER_PLAYER_FAILURE: 'REGISTER_PLAYER_FAILURE',
    CONFIRM_REGISTER_INIT: 'CONFIRM_REGISTER_INIT',
    CONFIRM_REGISTER_SUCCESS: 'CONFIRM_REGISTER_SUCCESS',
    CONFIRM_REGISTER_FAILURE: 'CONFIRM_REGISTER_FAILURE',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    SAVE_ITEM_INIT: 'SAVE_ITEM_INIT',
    SAVE_ITEM_SUCCESS: 'SAVE_ITEM_SUCCESS',
    SAVE_ITEM_FAILURE: 'SAVE_ITEM_FAILURE',
    GET_TOURNAMENTS_INIT: 'GET_TOURNAMENTS_INIT',
    GET_TOURNAMENTS_SUCCESS: 'GET_TOURNAMENTS_SUCCESS',
    GET_TOURNAMENTS_FAILURE: 'GET_TOURNAMENTS_FAILURE',
    GET_TOURNAMENT_BY_ID_INIT: 'GET_TOURNAMENT_BY_ID_INIT',
    GET_TOURNAMENT_BY_ID_SUCCESS: 'GET_TOURNAMENT_BY_ID_SUCCESS',
    GET_TOURNAMENT_BY_ID_FAILURE: 'GET_TOURNAMENT_BY_ID_FAILURE',
    GET_TOURNAMENTS_NAVBAR_INIT: 'GET_TOURNAMENTS_NAVBAR_INIT',
    GET_TOURNAMENTS_NAVBAR_SUCCESS: 'GET_TOURNAMENTS_NAVBAR_SUCCESS',
    GET_TOURNAMENTS_NAVBAR_FAILURE: 'GET_TOURNAMENTS_NAVBAR_FAILURE',
    GET_UPCOMING_TOURNAMENT_INIT: 'GET_UPCOMING_TOURNAMENT_INIT',
    GET_UPCOMING_TOURNAMENT_SUCCESS: 'GET_UPCOMING_TOURNAMENT_SUCCESS',
    GET_UPCOMING_TOURNAMENT_FAILURE: 'GET_UPCOMING_TOURNAMENT_FAILURE',
    GET_MANAGER_TOURNAMENTS_INIT: 'GET_MANAGER_TOURNAMENTS_INIT',
    GET_MANAGER_TOURNAMENTS_SUCCESS: 'GET_MANAGER_TOURNAMENTS_SUCCESS',
    GET_MANAGER_TOURNAMENTS_FAILURE: 'GET_MANAGER_TOURNAMENTS_FAILURE',
    GET_RANKING_INIT: 'GET_RANKING_INIT',
    GET_RANKING_SUCCESS: 'GET_RANKING_SUCCESS',
    GET_RANKING_FAILURE: 'GET_RANKING_FAILURE',
    GET_CITIES_INIT: 'GET_CITIES_INIT',
    GET_CITIES_SUCCESS: 'GET_CITIES_SUCCESS',
    GET_CITIES_FAILURE: 'GET_CITIES_FAILURE',
    GET_PLAYERS_INIT: 'GET_PLAYERS_INIT',
    GET_PLAYERS_SUCCESS: 'GET_PLAYERS_SUCCESS',
    GET_PLAYERS_FAILURE: 'GET_PLAYERS_FAILURE',
    GET_SIZES_INIT: 'GET_SIZES_INIT',
    GET_SIZES_SUCCESS: 'GET_SIZES_SUCCESS',
    GET_SIZES_FAILURE: 'GET_SIZES_FAILURE',
    GET_TEAMS_INIT: 'GET_TEAMS_INIT',
    GET_TEAMS_SUCCESS: 'GET_TEAMS_SUCCESS',
    GET_TEAMS_FAILURE: 'GET_TEAMS_FAILURE',
    GET_GAMES_INIT: 'GET_GAMES_INIT',
    GET_GAMES_SUCCESS: 'GET_GAMES_SUCCESS',
    GET_GAMES_FAILURE: 'GET_GAMES_FAILURE',
    GET_PLAYER_HISTORY_INIT: 'GET_PLAYER_HISTORY_INIT',
    GET_PLAYER_HISTORY_SUCCESS: 'GET_PLAYER_HISTORY_SUCCESS',
    GET_PLAYER_HISTORY_FAILURE: 'GET_PLAYER_HISTORY_FAILURE',
    GET_USER_PROFILE_INIT: 'GET_USER_PROFILE_INIT',
    GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
    GET_USER_PROFILE_FAILURE: 'GET_USER_PROFILE_FAILURE',
    GET_SCHEDULES_INIT: 'GET_SCHEDULES_INIT',
    GET_SCHEDULES_SUCCESS: 'GET_SCHEDULES_SUCCESS',
    GET_SCHEDULES_FAILURE: 'GET_SCHEDULES_FAILURE',
    GET_SPONSORS_INIT: 'GET_SPONSORS_INIT',
    GET_SPONSORS_SUCCESS: 'GET_SPONSORS_SUCCESS',
    GET_SPONSORS_FAILURE: 'GET_SPONSORS_FAILURE',
    GET_USERS_INIT: 'GET_USERS_INIT',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    GET_USERS_FAILURE: 'GET_USERS_FAILURE',
    GET_TOURNAMENT_RANKINGS_INIT: 'GET_TOURNAMENT_RANKINGS_INIT',
    GET_TOURNAMENT_RANKINGS_SUCCESS: 'GET_TOURNAMENT_RANKINGS_SUCCESS',
    GET_TOURNAMENT_RANKINGS_FAILURE: 'GET_TOURNAMENT_RANKINGS_FAILURE',
    DOWNLOAD_REPORT_INIT: 'DOWNLOAD_REPORT_INIT',
    DOWNLOAD_REPORT_SUCCESS: 'DOWNLOAD_REPORT_SUCCESS',
    DOWNLOAD_REPORT_FAILURE: 'DOWNLOAD_REPORT_FAILURE',
    GET_DOCUMENTS_INIT: 'GET_DOCUMENTS_INIT',
    GET_DOCUMENTS_SUCCESS: 'GET_DOCUMENTS_SUCCESS',
    GET_DOCUMENTS_FAILURE: 'GET_DOCUMENTS_FAILURE',
    UPLOAD_DOCUMENTS_INIT: 'UPLOAD_DOCUMENTS_INIT',
    UPLOAD_DOCUMENTS_SUCCESS: 'UPLOAD_DOCUMENTS_SUCCESS',
    UPLOAD_DOCUMENTS_FAILURE: 'UPLOAD_DOCUMENTS_FAILURE',
};

export const LOCAL_STORAGE_TAGS = {
    BVR_USER_TOKEN: 'BVR_USER_TOKEN',
    BVR_MUST_RESET_PASSWORD: 'BVR_MUST_RESET_PASSWORD',
    BVR_USER_PROFILE: 'BVR_USER_PROFILE',
    BVR_EVENT_DETAILS: 'BVR_EVENT_DETAILS',
};

export const PERMISSIONS = {
    Tournaments: 'Tournaments',
    Games: 'Games',
    Teams: 'Teams',
    Sizes: 'Sizes',
    Cities: 'Cities',
    Register: 'Register',
    Agreements: 'Agreements',
    RolePermissions: 'RolePermissions',
    Players: 'Players',
    PlayerHistories: 'PlayerHistories',
    PlayerNumberSizes: 'PlayerNumberSizes',
    Schedules: 'Schedules',
    Sponsors: 'Sponsors',
    TournamentRankings: 'TournamentRankings',
    Users: 'Users',
    SuperAdmin: 'Super',
};

export const PERMISSION_TYPES={
    ADMIN: 'ADMIN',
    VIEW: 'VIEW',
    ADD:'ADD',
    UPDATE:'UPDATE',
    DELETE:'DELETE',
};

export const ROLE_TYPES={
    SUPER_ADMIN: 'SuperAdmin',
    ADMIN: 'IsAdmin',
    MANAGER: 'TournamentManager'
};
export const NOTIFICATION_DEFAULT_TIMEOUT = 7000;
export const NOTIFICATION_ANIMATION = 'fade up'; //semantic ui enum

export const ADMIN_MENU_CATEGORY = {
    TOURNAMENTS: 'Tournaments',
    PLAYERS: 'Players',
    GENERAL: 'General Information',
};

export const DOC_TYPES = {
    RULES: 1,
    TERMS_CONDITIONS: 2,
    OTHER: 3
};
