import React, {useEffect, useState} from 'react';
import {Col, Row, Form} from 'react-bootstrap';
import _ from 'lodash';
import {TimePicker, DatePicker, KeyboardTimePicker} from "@material-ui/pickers";
import moment from 'moment';

export const CustomInputComponent = (
    {
        type = 'text',
        label,
        format,
        placeholder = label,
        input,
        field, // { name, value, onChange, onBlur }
        form: {touched, errors, setFieldValue, setFieldTouched}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        ...props
    }) => {
    
    if (field.value && field.value === '') {
        field.value = undefined;
        setFieldValue(field.name, undefined);
    }
    
    return (
        <div>
            {label &&
             <div className={'label'}>
                 {label}&nbsp;
                 {errors[field.name] && <span style={{color: '#961329'}}>*</span>}
             </div>
            }
            <Form.Control
                type={type}
                placeholder={placeholder}
                isInvalid={errors[field.name] && touched[field.name]}
                {...field}
                {...props}
            />
            
            {errors[field.name] && touched[field.name] &&
             <div className="field-error">{errors[field.name]}</div>}
        </div>
    );
};

export const CustomInputFileComponent = (
    {
        type = 'file',
        label,
        format,
        placeholder = '',
        input,
        field, // { name, value, onChange, onBlur }
        form: {touched, errors, setFieldValue, setFieldTouched}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        ...props
    }) => {
    
    if (field.value && field.value === '') {
        field.value = undefined;
        setFieldValue(field.name, undefined);
    }
    
    return (
        <div>
            {label &&
             <div className={'label'}>
                 {label}&nbsp;
                 {errors[field.name] && <span style={{color: '#961329'}}>*</span>}
             </div>
            }
            <Form.Control
                type={type}
                placeholder={placeholder}
                isInvalid={errors[field.name] && touched[field.name]}
                {...field}
                {...props}
            />
            
            {errors[field.name] && touched[field.name] &&
             <div className="field-error">{errors[field.name]}</div>}
        </div>
    );
};

export const CustomTextAreaComponent = (
    {
        label,
        format,
        placeholder = '',
        field, // { name, value, onChange, onBlur }
        form: {touched, errors, setFieldTouched}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        ...props
    }) => (
    <div>
        {label &&
         <div className={'label'}>
             {label}&nbsp;
             {errors[field.name] && <span style={{color: '#961329'}}>*</span>}
         </div>
        }
        <Form.Control
            as={'textarea'}
            placeholder={placeholder}
            isInvalid={errors[field.name] && touched[field.name]}
            {...field}
            {...props}
        />
        
        {errors[field.name] && touched[field.name] &&
         <div className="field-error">{errors[field.name]}</div>}
    </div>
);


export const CustomDropdownComponent = (
    {
        label,
        field, // { name, value, onChange, onBlur }
        form: {touched, errors, submitForm, status, setFieldTouched, setFieldValue, values}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        options = [],
        placeholder = 'Select...',
        submitOnChange,
        noEmptyField,
        onChangeCallback,
        ...props
    }) => {
    
    let optionsWithNull = [
        {id: null, name: placeholder},
        ...options
    ];
    
    // let dropdownOptions = noEmptyField ? options : optionsWithNull;
    let dropdownOptions = options;
    let isDisabled = typeof props.disabled === 'boolean'
                     ? props.disabled
                     : noEmptyField
                       ? options.length < 1
                       : optionsWithNull.length < 2;
    
    useEffect(() => {
        if (field.value && !options.find(option => option.id === +field.value)) {
            setFieldValue(field.name, undefined);
        }
    }, [options]);
    
    return (
        
        <div>
            {label &&
             <div className={'label'}>
                 {label}&nbsp;
                 {errors[field.name] && <span style={{color: '#961329'}}>*</span>}
             </div>
            }
            
            <Form.Control
                as="select"
                isInvalid={errors[field.name] && touched[field.name]}
                onChange={(e) => {
                    const {value} = e.target;
                    field.onChange(e);
                    submitOnChange && submitForm();
                    onChangeCallback && onChangeCallback(value,options.find(option => option.id === +value));
                }}
                onBlur={(e) => {
                    field.onBlur(e);
                    setFieldTouched(field.name);
                }}
                value={field.value}
                name={field.name}
                disabled={isDisabled}
                {...props}
            >
                {isDisabled &&
                 <option style={{color: 'grey'}}>
                     {/*No options available*/}
                 </option>
                }
                {!noEmptyField &&
                 <option style={{color: 'lightgrey'}} value={''} key={-1}>
                     {placeholder}
                 </option>
                }
                {dropdownOptions.map((opt, index) => (
                    <option key={index} value={opt.id} disabled={opt.disabled}>{opt.name || opt.fullName}</option>
                ))}
            </Form.Control>
            
            {errors[field.name] && _.get(touched, field.name.toString()) &&
             <div className="field-error">{errors[field.name]}</div>}
        </div>
    );
};

export const CustomCheckboxComponent = (
    {
        type = 'checkbox',
        label,
        format,
        placeholder = label,
        input,
        field, // { name, value, onChange, onBlur }
        form: {touched, errors, submitForm, setFieldTouched}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        submitOnChange,
        ...props
    }) => {
    
    return (
        <div>
            <Form.Group
                controlId={field.name}
                {...field}{...props}
            >
                <Form.Check
                    type={type}
                    onChange={(e) => {
                        field.onChange(e);
                        submitOnChange && submitForm();
                    }}
                    label={<span>
                        {label}&nbsp;
                        {errors[field.name] && <span style={{color: '#961329'}}>*</span>}
                    </span>}
                    checked={field.value} isInvalid={errors[field.name] && touched[field.name]}
                />
                
                {errors[field.name] && touched[field.name] &&
                 <div className="field-error">{errors[field.name]}</div>}
            </Form.Group>
        
        </div>
    );
};

export const CustomRadioCheckComponent = (
    {
        type = 'radio',
        label,
        format,
        placeholder = label,
        input,
        field, // { name, value, onChange, onBlur }
        form: {touched, errors, submitForm, values}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        submitOnChange,
        itemsDirection = '',
        onChangeCallback,
        ...props
    }) => {
    
    return (
        <div style={{paddingRight: '8px'}}>
            <Form.Group
                controlId={field.name}
            >
                <Form.Check
                    type={type}
                    value={field.value}
                    name={field.name}
                    checked={+values[field.name] === +field.value}
                    onChange={(e, value) => {
                        field.onChange(e, value);
                        submitOnChange && submitForm();
                        onChangeCallback && onChangeCallback(e.target.value);
                    }}
                    {...props}
                    id={field.value}
                    label={label}
                    isInvalid={errors[field.name]}/>
                
                {errors[field.name] &&
                 <div className="field-error">{errors[field.name]}</div>}
            </Form.Group>
        
        </div>
    );
};

export const CustomTimePickerComponent = (
    {
        label,
        format,
        placeholder = '',
        input,
        field, // { name, value, onChange, onBlur }
        form: {touched, errors, setFieldValue, setFieldTouched}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        ...props
    }) => {
    const [timeValue, setTimeValue] = useState(null);
    
    useEffect(() => {
        if (field.value) {
            const hour = field.value.split(":")[0];
            const min = field.value.split(":")[1];
            const date = moment().set({hour: hour, minute: min});
            setTimeValue(date);
        } else {
            setFieldValue(field.name, null)
        }
    }, []);
    
    return (
        <div>
            {label &&
             <div className={'label'}>
                 {label}&nbsp;
                 {errors[field.name] && <span style={{color: '#961329'}}>*</span>}
             </div>
            }
            <KeyboardTimePicker
                ampm={false}
                format={'HH:mm'}
                {...field}
                {...props}
                value={timeValue}
                onChange={(date) => {
                    setTimeValue(date);
                    if(date){
                        setFieldValue(field.name, date.format('HH:mm'))
                    }
                    else{
                        setFieldValue(field.name, null)
                    }
                    
                }}
                onBlur={(e) => {
                    field.onBlur(e);
                    setFieldTouched(field.name);
                }}
            />
            
            {errors[field.name] && touched[field.name] &&
             <div className="field-error">{errors[field.name]}</div>}
        </div>
    );
};

export const CustomDatePickerComponent = (
    {
        label,
        format,
        placeholder = '',
        input,
        field, // { name, value, onChange, onBlur }
        form: {touched, errors, setFieldValue, setFieldTouched}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        ...props
    }) => {
    const [dateValue, setDateValue] = useState(null);
    
    useEffect(() => {
        if (field.value) {
            const date = moment(field.value);
            setDateValue(date);
        } else {
            setFieldValue(field.name, null)
        }
    }, []);
    
    return (
        <div>
            {label &&
             <div className={'label'}>
                 {label}&nbsp;
                 {errors[field.name] && <span style={{color: '#961329'}}>*</span>}
             </div>
            }
            <DatePicker
                format={'YYYY-MM-DD'}
                placeholder={placeholder}
                {...field}
                {...props}
                value={dateValue}
                onChange={(date) => {
                    setDateValue(date);
                    setFieldValue(field.name, date.format('YYYY-MM-DD'))
                }}
                onBlur={(e) => {
                    field.onBlur(e);
                    setFieldTouched(field.name);
                }}
            />
            
            {errors[field.name] && touched[field.name] &&
             <div className="field-error">{errors[field.name]}</div>}
        </div>
    );
};


