import Axios from 'axios';
import {getToken} from 'utils/index';
import store from 'config/redux';
import * as AppActions from 'actions';

const headers = {
    'Accept': 'application/json'
    // 'Content-Type': 'application/json'
};
const token = getToken();

if (token) {
    headers.Authorization = `Bearer ${token}`;
}
else {
    // headers.Authorization = undefined;
}
const http = Axios.create({
    // baseURL: 'https://beachvolleyromania.ro/WebApi/api/', //PROD ENV
    baseURL: 'http://web.beachvolleyromania.ro/WebApi/api/', //DEV ENV
    headers
});

http.interceptors.response.use((response) => {
        return response;
    },
    (error) => {
        if (error && error.response) {
            if(error.response.data.error.message){
                store.dispatch(AppActions.notifyError(error.response.data.error.message));
            }
            else {
                store.dispatch(AppActions.notifyError('Something went wrong. Try again or contact the support team.'));
            }
            
        }
        return Promise.reject(error);
    }
);

export default http;