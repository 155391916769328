import React from 'react';
import http from "config/http";
import {APIs, ACTIONS_TYPES, LOCAL_STORAGE_TAGS, PATH_URLS} from 'constants/index';
import jwt from 'jsonwebtoken';
import {push} from 'react-router-redux';
import {checkRole, getToken} from 'utils/index';

export const switchRoute = path => (dispatch) => {
    dispatch(push(path));
};
//region NOTIFICATIONS
const notify = (icon, color, className) => (message) => (dispatch) => {
    return dispatch(
        addNotification({
            icon,
            color,
            className,
            message
        })
    );
};

export const notifySuccess = notify('success', '#139680', 'notification-success');
export const notifyWarning = notify('warning', '#fd9a03', 'notification-warning');
export const notifyError = notify('error', '#961329', 'notification-error');

const addNotification = (payload) => ({type: ACTIONS_TYPES.ADD_NOTIFICATION, payload});
export const removeNotification = (payload) => (dispatch) => dispatch({
    type: ACTIONS_TYPES.REMOVE_NOTIFICATION,
    payload
});

//endregion NOTIFICATIONS

//region AUTHENTICATION
export const refreshLogin = () => async dispatch => {
    const resetPass = localStorage.getItem(LOCAL_STORAGE_TAGS.BVR_MUST_RESET_PASSWORD);
    const userProfile = JSON.parse(localStorage.getItem(LOCAL_STORAGE_TAGS.BVR_USER_PROFILE));
    if (resetPass === 'true') {
        await dispatch(switchRoute(PATH_URLS.CHANGE_PASSWORD));
        // dispatch(onFirstLoginResetPass(true));
    } else {
        const authToken = getToken();
        const parseJwt = jwt.decode(authToken);
        let current_time = new Date().getTime() / 1000;

        if (authToken) {
            if ((parseJwt && current_time > parseJwt['exp']) || authToken === '') {
                dispatch(notifyWarning('Your authentication token has expired! '));
                dispatch(logout());
                dispatch(getUserProfileFailure());
            } else {
                dispatch(getUserProfileSuccess(userProfile));
                dispatch(loginSuccess(authToken));
            }
        } else {
            dispatch(getUserProfileFailure());
        }
    }
};

export const login = (body) => async dispatch => {
    dispatch(loginInit());
    let response;
    try {
        response = await http.post(APIs.USER, body);
    } catch (e) {
        dispatch(loginFailure());
        dispatch(notifyError('An error has occurred !'));
    }

    if (response.data && response.data.token) {

        http.defaults.headers['Authorization'] = `Bearer ${response.data.token}`;

        await dispatch(getUserProfile());
        if (response.data.mustReset) {
            dispatch(loginSuccess(response.data.token));
            dispatch(switchRoute(PATH_URLS.CHANGE_PASSWORD));
            localStorage.setItem(LOCAL_STORAGE_TAGS.BVR_MUST_RESET_PASSWORD, response.data.mustReset);
        } else {
            localStorage.setItem(LOCAL_STORAGE_TAGS.BVR_USER_TOKEN, response.data.token);
            dispatch(loginSuccess(response.data.token));
            if (checkRole('SuperAdmin')) {
                dispatch(switchRoute(PATH_URLS.ADMIN_PROFILE));
            } else {
                dispatch(switchRoute(PATH_URLS.HOME));
            }
            dispatch(notifySuccess('Login success'));
        }

        return response.data;
    }
};

const loginInit = () => ({type: ACTIONS_TYPES.LOGIN_INIT});
const loginSuccess = (payload) => ({type: ACTIONS_TYPES.LOGIN_SUCCESS, payload});
const loginFailure = () => ({type: ACTIONS_TYPES.LOGIN_FAILURE});

export const logout = () => dispatch => {
    localStorage.removeItem(LOCAL_STORAGE_TAGS.BVR_USER_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_TAGS.BVR_MUST_RESET_PASSWORD);
    localStorage.removeItem(LOCAL_STORAGE_TAGS.BVR_USER_PROFILE);
    dispatch(logoutSuccess());
    dispatch(switchRoute(PATH_URLS.LOGIN));
};
const logoutSuccess = () => ({type: ACTIONS_TYPES.LOGOUT_SUCCESS});

export const resetPassword = (credentials) => async dispatch => {
    dispatch(resetPasswordInit());
    try {
        await http.put(`${APIs.USERS}/${credentials.userId}`, credentials);
        dispatch(resetPasswordSuccess());
        dispatch(switchRoute(PATH_URLS.USER_PROFILE));
        localStorage.removeItem(LOCAL_STORAGE_TAGS.BVR_MUST_RESET_PASSWORD);
        dispatch(notifySuccess('Password changed'));
    } catch (e) {
        dispatch(resetPasswordFailure());
        dispatch(notifyError('Password cannot be changed'));
    }
};
const resetPasswordInit = () => ({type: ACTIONS_TYPES.RESET_PASSWORD_INIT});
const resetPasswordSuccess = (payload) => ({type: ACTIONS_TYPES.RESET_PASSWORD_SUCCESS, payload});
const resetPasswordFailure = () => ({type: ACTIONS_TYPES.RESET_PASSWORD_FAILURE});

export const forgotPassword = (body) => async dispatch => {
    dispatch(forgotPasswordInit());
    try {
        await http.put(`${APIs.USERS}?email=${body.email}`);
        dispatch(forgotPasswordSuccess());
        dispatch(switchRoute(PATH_URLS.LOGIN));
        dispatch(notifySuccess('You should receive an e-mail soon with your new password.'));
    } catch (e) {
        dispatch(forgotPasswordFailure());
        dispatch(notifyError('Something went wrong. Please try again!'));
    }
};
const forgotPasswordInit = () => ({type: ACTIONS_TYPES.FORGOT_PASSWORD_INIT});
const forgotPasswordSuccess = (payload) => ({type: ACTIONS_TYPES.FORGOT_PASSWORD_SUCCESS, payload});
const forgotPasswordFailure = () => ({type: ACTIONS_TYPES.FORGOT_PASSWORD_FAILURE});


export const registerPlayer = (body) => async dispatch => {
    dispatch(registerPlayerInit());
    try {
        await http.post(APIs.PLAYERS, body);
        dispatch(registerPlayerSuccess());
        dispatch(notifySuccess('Your account have successfully been created. You just have to confirm it from your e-mail.'));
        dispatch(switchRoute(PATH_URLS.LOGIN));
    } catch (e) {
        dispatch(registerPlayerFailure());
        dispatch(notifyError('An error occurred. Your account has not been created !'));
    }
};
const registerPlayerInit = () => ({type: ACTIONS_TYPES.REGISTER_PLAYER_INIT});
const registerPlayerSuccess = (payload) => ({type: ACTIONS_TYPES.REGISTER_PLAYER_SUCCESS, payload});
const registerPlayerFailure = () => ({type: ACTIONS_TYPES.REGISTER_PLAYER_FAILURE});


export const confirmRegister = (params) => async dispatch => {
    dispatch(confirmRegisterInit());
    try {
        await http.get(APIs.CONFIRM_REGISTER, {params});

        localStorage.setItem(LOCAL_STORAGE_TAGS.BVR_USER_TOKEN, params.token);
        http.defaults.headers['Authorization'] = `Bearer ${params.token}`;

        await dispatch(getUserProfile());
        dispatch(loginSuccess(params.token));

        dispatch(confirmRegisterSuccess());
        dispatch(notifySuccess('Welcome! You have successfully logged in.'));
        dispatch(switchRoute(PATH_URLS.HOME));
    } catch (e) {
        dispatch(confirmRegisterFailure());
        dispatch(switchRoute(PATH_URLS.LOGIN));
        dispatch(notifyError('An error occurred. Your account has not been confirmed !'));
    }
};
const confirmRegisterInit = () => ({type: ACTIONS_TYPES.CONFIRM_REGISTER_INIT});
const confirmRegisterSuccess = (payload) => ({type: ACTIONS_TYPES.CONFIRM_REGISTER_SUCCESS, payload});
const confirmRegisterFailure = () => ({type: ACTIONS_TYPES.CONFIRM_REGISTER_FAILURE});


//endregion AUTHENTICATION

//region USER PROFILEf

export const getUserProfile = () => async dispatch => {
    dispatch(getUserProfileInit());
    try {
        const response = await http.get(APIs.USER_PROFILE);
        dispatch(getUserProfileSuccess(response.data));
        localStorage.setItem(LOCAL_STORAGE_TAGS.BVR_USER_PROFILE, JSON.stringify(response.data));
        return response.data;
    } catch (e) {
        dispatch(getUserProfileFailure());
    }
};
export const updateUserProfile = (body) => async dispatch => {
    dispatch(saveItemInit());
    try {
        const response = await http.put(`${APIs.USER_PROFILE}/${body.id}`, body);
        dispatch(saveItemSuccess());
        dispatch(notifySuccess('Your profile information was successfully updated'));
        return response.data;
    } catch (e) {
        dispatch(saveItemFailure());
        dispatch(notifyError('An error occurred. Your profile information could not have been saved !'));
    }
};

const getUserProfileInit = () => ({type: ACTIONS_TYPES.GET_USER_PROFILE_INIT});
const getUserProfileSuccess = (payload) => ({type: ACTIONS_TYPES.GET_USER_PROFILE_SUCCESS, payload});
const getUserProfileFailure = () => ({type: ACTIONS_TYPES.GET_USER_PROFILE_FAILURE});
//endregion USER PROFILE

const saveItemInit = () => ({type: ACTIONS_TYPES.SAVE_ITEM_INIT});
const saveItemSuccess = (payload) => ({type: ACTIONS_TYPES.SAVE_ITEM_SUCCESS, payload});
const saveItemFailure = () => ({type: ACTIONS_TYPES.SAVE_ITEM_FAILURE});

//region TOURNAMENTS
export const getTournaments = (params) => async dispatch => {
    dispatch(getTournamentsInit());
    try {
        const response = await http.get(APIs.TOURNAMENT, {params});
        dispatch(getTournamentsSuccess(response.data));
        return response.data;
    } catch (e) {
        dispatch(getTournamentsFailure());
    }
};
export const resetTournaments = () => dispatch => dispatch(getTournamentsSuccess([]));
const getTournamentsInit = () => ({type: ACTIONS_TYPES.GET_TOURNAMENTS_INIT});
const getTournamentsSuccess = (payload) => ({type: ACTIONS_TYPES.GET_TOURNAMENTS_SUCCESS, payload});
const getTournamentsFailure = () => ({type: ACTIONS_TYPES.GET_TOURNAMENTS_FAILURE});

export const getTournamentById = (id) => async dispatch => {
    dispatch(getTournamentByIdInit());
    try {
        const response = await http.get(`${APIs.TOURNAMENT}/${id}`);
        dispatch(getTournamentByIdSuccess(response.data));
        return response.data;
    } catch (e) {
        dispatch(getTournamentByIdFailure());
    }
};
export const resetTournamentById = () => dispatch => dispatch(getTournamentByIdSuccess([]));
const getTournamentByIdInit = () => ({type: ACTIONS_TYPES.GET_TOURNAMENT_BY_ID_INIT});
const getTournamentByIdSuccess = (payload) => ({type: ACTIONS_TYPES.GET_TOURNAMENT_BY_ID_SUCCESS, payload});
const getTournamentByIdFailure = () => ({type: ACTIONS_TYPES.GET_TOURNAMENT_BY_ID_FAILURE});

export const getTournamentsNavBar = (params) => async dispatch => {
    dispatch(getTournamentsNavBarInit());
    try {
        const response = await http.get(APIs.TOURNAMENT, {params});
        dispatch(getTournamentsNavBarSuccess(response.data));
        return response.data;
    } catch (e) {
        dispatch(getTournamentsNavBarFailure());
    }
};
export const resetTournamentsNavBar = () => dispatch => dispatch(getTournamentsNavBarSuccess([]));
const getTournamentsNavBarInit = () => ({type: ACTIONS_TYPES.GET_TOURNAMENTS_NAVBAR_INIT});
const getTournamentsNavBarSuccess = (payload) => ({type: ACTIONS_TYPES.GET_TOURNAMENTS_NAVBAR_SUCCESS, payload});
const getTournamentsNavBarFailure = () => ({type: ACTIONS_TYPES.GET_TOURNAMENTS_NAVBAR_FAILURE});


export const getManagerTournaments = (params) => async dispatch => {
    dispatch(getManagerTournamentsInit());
    try {
        const response = await http.get(APIs.MANAGER_TOURNAMENT, {params});
        dispatch(getManagerTournamentsSuccess(response.data));
        return response.data;
    } catch (e) {
        dispatch(getManagerTournamentsFailure());
    }
};

export const resetManagerTournaments = () => dispatch => dispatch(getManagerTournamentsSuccess([]));
const getManagerTournamentsInit = () => ({type: ACTIONS_TYPES.GET_MANAGER_TOURNAMENTS_INIT});
const getManagerTournamentsSuccess = (payload) => ({type: ACTIONS_TYPES.GET_MANAGER_TOURNAMENTS_SUCCESS, payload});
const getManagerTournamentsFailure = () => ({type: ACTIONS_TYPES.GET_MANAGER_TOURNAMENTS_FAILURE});


export const getUpcomingTournament = () => async dispatch => {
    dispatch(getUpcomingTournamentInit());
    try {
        const response = await http.get(APIs.UPCOMING_TOURNAMENT);
        dispatch(getUpcomingTournamentSuccess(response.data));
        return response.data;
    } catch (e) {
        dispatch(getUpcomingTournamentFailure());
    }
};
const getUpcomingTournamentInit = () => ({type: ACTIONS_TYPES.GET_UPCOMING_TOURNAMENT_INIT});
const getUpcomingTournamentSuccess = (payload) => ({type: ACTIONS_TYPES.GET_UPCOMING_TOURNAMENT_SUCCESS, payload});
const getUpcomingTournamentFailure = () => ({type: ACTIONS_TYPES.GET_UPCOMING_TOURNAMENT_FAILURE});


export const addTournament = (body) => async dispatch => {
    dispatch(saveItemInit());
    const bodyFormData = new FormData();
    Object.keys(body).map(key => {
        if (body[key]) {
            bodyFormData.append(key, body[key]);
        }

    });
    try {
        const response = await http.post(APIs.TOURNAMENT, bodyFormData);
        dispatch(saveItemSuccess(response.data));
        dispatch(notifySuccess('New tournament added'));
        return response.data;
    } catch (e) {
        dispatch(saveItemFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};
export const editTournament = (body) => async dispatch => {
    dispatch(saveItemInit());
    const bodyFormData = new FormData();
    Object.keys(body).map(key => {
        if (body[key]) {
            bodyFormData.append(key, body[key]);
        }
    });
    try {
        const response = await http.put(`${APIs.TOURNAMENT}/${body.id}`, bodyFormData);
        dispatch(saveItemSuccess(response.data));
        dispatch(notifySuccess('Tournament updated'));
        return response.data;
    } catch (e) {
        dispatch(saveItemFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};

export const deleteTournament = (body) => async dispatch => {
    dispatch(saveItemInit());

    try {
        const response = await http.delete(`${APIs.TOURNAMENT}/${body.id}`);
        dispatch(saveItemSuccess(response.data));
        dispatch(notifySuccess('Tournament removed'));
        return response.data;
    } catch (e) {
        dispatch(saveItemFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};

//endregion TOURNAMENTS


// region REGISTER TEAM
export const registerTeam = (body) => async dispatch => {
    dispatch(saveItemInit());
    try {
        const response = await http.post(APIs.REGISTER, body);
        dispatch(saveItemSuccess());
        dispatch(notifySuccess('Successfully registered !'));
        return response.data;
    } catch (e) {
        dispatch(saveItemFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};
// endregion REGISTER TEAM

//region RANKING
export const getRanking = (params) => async dispatch => {
    dispatch(getRankingInit());
    try {
        const response = await http.get(APIs.RANK, {params});
        dispatch(getRankingSuccess(response.data));
        return response.data;
    } catch (e) {
        dispatch(getRankingFailure());
    }
};
export const resetRanking = () => dispatch => dispatch(getRankingSuccess([]));
const getRankingInit = () => ({type: ACTIONS_TYPES.GET_RANKING_INIT});
const getRankingSuccess = (payload) => ({type: ACTIONS_TYPES.GET_RANKING_SUCCESS, payload});
const getRankingFailure = () => ({type: ACTIONS_TYPES.GET_RANKING_FAILURE});
//endregion RANKING

//region CITIES
export const getCities = () => async dispatch => {
    dispatch(getCitiesInit());
    try {
        const response = await http.get(APIs.CITY);
        dispatch(getCitiesSuccess(response.data));
        return response.data;
    } catch (e) {
        dispatch(getCitiesFailure());
    }
};
export const resetCities = () => dispatch => dispatch(getCitiesSuccess([]));
const getCitiesInit = () => ({type: ACTIONS_TYPES.GET_CITIES_INIT});
const getCitiesSuccess = (payload) => ({type: ACTIONS_TYPES.GET_CITIES_SUCCESS, payload});
const getCitiesFailure = () => ({type: ACTIONS_TYPES.GET_CITIES_FAILURE});

//endregion CITIES
//
// region SIZES
export const getSizes = () => async dispatch => {
    dispatch(getSizesInit());
    try {
        const response = await http.get(APIs.SIZE);
        dispatch(getSizesSuccess(response.data));
        return response.data;
    } catch (e) {
        dispatch(getSizesFailure());
    }
};
export const resetSizes = () => dispatch => dispatch(getSizesSuccess([]));
const getSizesInit = () => ({type: ACTIONS_TYPES.GET_SIZES_INIT});
const getSizesSuccess = (payload) => ({type: ACTIONS_TYPES.GET_SIZES_SUCCESS, payload});
const getSizesFailure = () => ({type: ACTIONS_TYPES.GET_SIZES_FAILURE});

//endregion SIZES
//
// region PLAYERS
export const getPlayers = (params) => async dispatch => {
    dispatch(getPlayersInit());
    try {
        const response = await http.get(APIs.PLAYERS, {params});
        dispatch(getPlayersSuccess(response.data));
        return response.data;
    } catch (e) {
        dispatch(getPlayersFailure());
    }
};
export const resetPlayers = () => dispatch => dispatch(getPlayersSuccess([]));
const getPlayersInit = () => ({type: ACTIONS_TYPES.GET_PLAYERS_INIT});
const getPlayersSuccess = (payload) => ({type: ACTIONS_TYPES.GET_PLAYERS_SUCCESS, payload});
const getPlayersFailure = () => ({type: ACTIONS_TYPES.GET_PLAYERS_FAILURE});

export const addPlayer = (body) => async dispatch => {
    dispatch(saveItemInit());
    try {
        const response = await http.post(APIs.PLAYERS, body);
        dispatch(saveItemSuccess(response.data));
        dispatch(notifySuccess('New player added'));
        return response.data;
    } catch (e) {
        dispatch(saveItemFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};
export const editPlayer = (body) => async dispatch => {
    dispatch(saveItemInit());
    try {
        const response = await http.put(`${APIs.PLAYERS}/${body.id}`, body);
        dispatch(saveItemSuccess(response.data));
        dispatch(notifySuccess('Player updated'));
        return response.data;
    } catch (e) {
        dispatch(saveItemFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};

export const deletePlayer = (body) => async dispatch => {
    dispatch(saveItemInit());
    try {
        const response = await http.delete(`${APIs.PLAYERS}/${body.id}`);
        dispatch(saveItemSuccess(response.data));
        dispatch(notifySuccess('Team removed'));
        return response.data;
    } catch (e) {
        dispatch(saveItemFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};

//endregion PLAYERS
//
// region TEAMS
export const getTeams = (params) => async dispatch => {
    dispatch(getTeamsInit());
    try {
        const response = await http.get(APIs.TEAMS, {params});
        dispatch(getTeamsSuccess(response.data));
        return response.data;
    } catch (e) {
        dispatch(getTeamsFailure());
    }
};
export const resetTeams = () => dispatch => dispatch(getTeamsSuccess([]));
const getTeamsInit = () => ({type: ACTIONS_TYPES.GET_TEAMS_INIT});
const getTeamsSuccess = (payload) => ({type: ACTIONS_TYPES.GET_TEAMS_SUCCESS, payload});
const getTeamsFailure = () => ({type: ACTIONS_TYPES.GET_TEAMS_FAILURE});

export const addTeam = (body) => async dispatch => {
    dispatch(saveItemInit());
    try {
        const response = await http.post(APIs.TEAMS, body);
        dispatch(saveItemSuccess(response.data));
        dispatch(notifySuccess('New team added'));
        return response.data;
    } catch (e) {
        dispatch(saveItemFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};
export const editTeam = (body) => async dispatch => {
    dispatch(saveItemInit());
    try {
        const response = await http.put(`${APIs.TEAMS}/${body.teamId}`, body);
        dispatch(saveItemSuccess(response.data));
        dispatch(notifySuccess('Team updated'));
        return response.data;
    } catch (e) {
        dispatch(saveItemFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};

export const deleteTeam = (body) => async dispatch => {
    dispatch(saveItemInit());
    try {
        const response = await http.delete(`${APIs.TEAMS}/${body.teamId}`);
        dispatch(saveItemSuccess(response.data));
        dispatch(notifySuccess('Team removed'));
        return response.data;
    } catch (e) {
        dispatch(saveItemFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};

//endregion TEAMS
//
// region GAMES
export const getGames = (params) => async dispatch => {
    dispatch(getGamesInit());
    try {
        const response = await http.get(APIs.GAMES, {params});
        dispatch(getGamesSuccess(response.data));
        return response.data;
    } catch (e) {
        dispatch(getGamesFailure());
    }
};
export const resetGames = () => dispatch => dispatch(getGamesSuccess([]));
const getGamesInit = () => ({type: ACTIONS_TYPES.GET_GAMES_INIT});
const getGamesSuccess = (payload) => ({type: ACTIONS_TYPES.GET_GAMES_SUCCESS, payload});
const getGamesFailure = () => ({type: ACTIONS_TYPES.GET_GAMES_FAILURE});

export const addGame = (body) => async dispatch => {
    dispatch(saveItemInit());
    try {
        const response = await http.post(APIs.GAMES, body);
        dispatch(saveItemSuccess(response.data));
        dispatch(notifySuccess('New game added'));
        return response.data;
    } catch (e) {
        dispatch(saveItemFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};
export const editGame = (body) => async dispatch => {
    dispatch(saveItemInit());
    try {
        const response = await http.put(`${APIs.GAMES}/${body.id}`, body);
        dispatch(saveItemSuccess(response.data));
        dispatch(notifySuccess('Game updated'));
        return response.data;
    } catch (e) {
        dispatch(saveItemFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};

export const deleteGame = (body) => async dispatch => {
    dispatch(saveItemInit());
    try {
        const response = await http.delete(`${APIs.GAMES}/${body.id}`);
        dispatch(saveItemSuccess(response.data));
        dispatch(notifySuccess('Game removed'));
        return response.data;
    } catch (e) {
        dispatch(saveItemFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};

export const addGameResults = (body) => async dispatch => {
    dispatch(saveItemInit());
    try {
        const response = await http.post(APIs.GAME_RESULTS, body);
        dispatch(saveItemSuccess(response.data));
        dispatch(notifySuccess('Game results added'));
        return response.data;
    } catch (e) {
        dispatch(saveItemFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};
export const editGameResults = (body) => async dispatch => {
    dispatch(saveItemInit());
    try {
        const response = await http.put(`${APIs.GAME_RESULTS}`, body);
        dispatch(saveItemSuccess(response.data));
        dispatch(notifySuccess('Game results updated'));
        return response.data;
    } catch (e) {
        dispatch(saveItemFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};

export const generateGamesForTournament = (tournamentId) => async dispatch => {
    dispatch(saveItemInit());
    try {
        await http.post(`${APIs.GAMES}/GenerateGames?tournamentId=${tournamentId}`);
        dispatch(saveItemSuccess());
    } catch (e) {
        dispatch(saveItemFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};
//endregion GAMES
//
// region PLAYER HISTORY
export const getPlayerHistory = (params) => async dispatch => {
    dispatch(getPlayerHistoryInit());
    try {
        const response = await http.get(APIs.PLAYER_HISTORY, {params});
        dispatch(getPlayerHistorySuccess(response.data));
        return response.data;
    } catch (e) {
        dispatch(getPlayerHistoryFailure());
    }
};
export const resetPlayerHistory = () => dispatch => dispatch(getPlayerHistorySuccess([]));
const getPlayerHistoryInit = () => ({type: ACTIONS_TYPES.GET_PLAYER_HISTORY_INIT});
const getPlayerHistorySuccess = (payload) => ({type: ACTIONS_TYPES.GET_PLAYER_HISTORY_SUCCESS, payload});
const getPlayerHistoryFailure = () => ({type: ACTIONS_TYPES.GET_PLAYER_HISTORY_FAILURE});

//endregion PLAYER HISTORY
//
// region SCHEDULES
export const getSchedules = (params) => async dispatch => {
    dispatch(getSchedulesInit());
    try {
        const response = await http.get(APIs.SCHEDULE, {params});
        dispatch(getSchedulesSuccess(response.data));
        return response.data;
    } catch (e) {
        dispatch(getSchedulesFailure());
    }
};
export const resetSchedules = () => dispatch => dispatch(getSchedulesSuccess([]));
const getSchedulesInit = () => ({type: ACTIONS_TYPES.GET_SCHEDULES_INIT});
const getSchedulesSuccess = (payload) => ({type: ACTIONS_TYPES.GET_SCHEDULES_SUCCESS, payload});
const getSchedulesFailure = () => ({type: ACTIONS_TYPES.GET_SCHEDULES_FAILURE});

export const addSchedule = (body) => async dispatch => {
    dispatch(saveItemInit());
    try {
        const response = await http.post(APIs.SCHEDULE, body);
        dispatch(saveItemSuccess(response.data));
        dispatch(notifySuccess('New schedule added'));
        return response.data;
    } catch (e) {
        dispatch(saveItemFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};
export const editSchedule = (body) => async dispatch => {
    dispatch(saveItemInit());
    try {
        const response = await http.put(`${APIs.SCHEDULE}/${body.id}`, body);
        dispatch(saveItemSuccess(response.data));
        dispatch(notifySuccess('Schedule updated'));
        return response.data;
    } catch (e) {
        dispatch(saveItemFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};

export const deleteSchedule = (body) => async dispatch => {
    dispatch(saveItemInit());
    try {
        const response = await http.delete(`${APIs.SCHEDULE}/${body.id}`);
        dispatch(saveItemSuccess(response.data));
        dispatch(notifySuccess('Schedule removed'));
        return response.data;
    } catch (e) {
        dispatch(saveItemFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};

//endregion SCHEDULES
//
// region TOURNAMENT RANKINGS
export const getTournamentRankings = (params) => async dispatch => {
    dispatch(getTournamentRankingsInit());
    try {
        const response = await http.get(APIs.TOURNAMENT_RANKINGS, {params});
        dispatch(getTournamentRankingsSuccess(response.data));
        return response.data;
    } catch (e) {
        dispatch(getTournamentRankingsFailure());
    }
};
export const resetTournamentRankings = () => dispatch => dispatch(getTournamentRankingsSuccess([]));
const getTournamentRankingsInit = () => ({type: ACTIONS_TYPES.GET_TOURNAMENT_RANKINGS_INIT});
const getTournamentRankingsSuccess = (payload) => ({type: ACTIONS_TYPES.GET_TOURNAMENT_RANKINGS_SUCCESS, payload});
const getTournamentRankingsFailure = () => ({type: ACTIONS_TYPES.GET_TOURNAMENT_RANKINGS_FAILURE});

export const addTournamentRankings = (body) => async dispatch => {
    dispatch(saveItemInit());
    try {
        const response = await http.post(APIs.TOURNAMENT_RANKINGS, body);
        dispatch(saveItemSuccess(response.data));
        dispatch(notifySuccess('New ranking added'));
        return response.data;
    } catch (e) {
        dispatch(saveItemFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};
export const editTournamentRankings = (body) => async dispatch => {
    dispatch(saveItemInit());
    try {
        const response = await http.put(`${APIs.TOURNAMENT_RANKINGS}/${body.tournamentRankingId}`, body);
        dispatch(saveItemSuccess(response.data));
        dispatch(notifySuccess('Ranking updated'));
        return response.data;
    } catch (e) {
        dispatch(saveItemFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};

export const deleteTournamentRankings = (body) => async dispatch => {
    dispatch(saveItemInit());
    try {
        const response = await http.delete(`${APIs.TOURNAMENT_RANKINGS}/${body.tournamentRankingId}`);
        dispatch(saveItemSuccess(response.data));
        dispatch(notifySuccess('Ranking removed'));
        return response.data;
    } catch (e) {
        dispatch(saveItemFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};

//endregion TOURNAMENT RANKINGS

// region SPONSORS
export const getSponsors = (params) => async dispatch => {
    dispatch(getSponsorsInit());
    try {
        const response = await http.get(APIs.SPONSORS, {params});
        dispatch(getSponsorsSuccess(response.data));
        return response.data;
    } catch (e) {
        dispatch(getSponsorsFailure());
    }
};
export const resetSponsors = () => dispatch => dispatch(getSponsorsSuccess([]));
const getSponsorsInit = () => ({type: ACTIONS_TYPES.GET_SPONSORS_INIT});
const getSponsorsSuccess = (payload) => ({type: ACTIONS_TYPES.GET_SPONSORS_SUCCESS, payload});
const getSponsorsFailure = () => ({type: ACTIONS_TYPES.GET_SPONSORS_FAILURE});

//endregion SPONSORS

export const getUsers = (params) => async dispatch => {
    dispatch(getUsersInit());
    try {
        const response = await http.get(APIs.USERS, {params});
        dispatch(getUsersSuccess(response.data));
        return response.data;
    } catch (e) {
        dispatch(getUsersFailure());
    }
};

export const resetUsers = () => dispatch => dispatch(getUsersSuccess([]));
const getUsersInit = () => ({type: ACTIONS_TYPES.GET_USERS_INIT});
const getUsersSuccess = (payload) => ({type: ACTIONS_TYPES.GET_USERS_SUCCESS, payload});
const getUsersFailure = () => ({type: ACTIONS_TYPES.GET_USERS_FAILURE});


const saveAs = (blob, fileName) => {
    let url = window.URL.createObjectURL(blob);

    let anchorElem = document.createElement('a');
    anchorElem.href = url;
    anchorElem.download = fileName;
    document.body.appendChild(anchorElem);
    anchorElem.click();
    document.body.removeChild(anchorElem);
};
export const base64ToArrayBuffer = (data) => {
    const base64 = data.fileContents;
    let binaryString = atob(base64);
    let byteNumbers = new Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        byteNumbers[i] = binaryString.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let blob = new Blob([byteArray], {
        type: data.contentType
    });
    let fileName = data.fileDownloadName;
    saveAs(blob, fileName);
};


export const downloadTeamDetailsReport = (params) => async dispatch => {
    dispatch(downloadReportInit());
    try {
        const response = await http.get(APIs.REPORT_TEAMS_DETAILS, {params});
        base64ToArrayBuffer(response.data);

        dispatch(downloadReportSuccess());
        dispatch(notifySuccess('Report downloaded'));
    } catch (e) {
        dispatch(downloadReportFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};
export const downloadGamesTimetableReport = (params) => async dispatch => {
    dispatch(downloadReportInit());
    try {
        const response = await http.get(APIs.REPORT_GAMES_TIMETABLE, {params});
        base64ToArrayBuffer(response.data);

        dispatch(downloadReportSuccess());
        dispatch(notifySuccess('Report downloaded'));
    } catch (e) {
        dispatch(downloadReportFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};
export const downloadFinalRankingReport = (params) => async dispatch => {
    dispatch(downloadReportInit());
    try {
        const response = await http.get(APIs.REPORT_TEAMS_FINAL_RANKING, {params});
        base64ToArrayBuffer(response.data);

        dispatch(downloadReportSuccess());
        dispatch(notifySuccess('Report downloaded'));
    } catch (e) {
        dispatch(downloadReportFailure());
        dispatch(notifyError('An error has occurred !'));
    }
};

const downloadReportInit = () => ({type: ACTIONS_TYPES.DOWNLOAD_REPORT_INIT});
const downloadReportSuccess = (payload) => ({type: ACTIONS_TYPES.DOWNLOAD_REPORT_SUCCESS, payload});
const downloadReportFailure = () => ({type: ACTIONS_TYPES.DOWNLOAD_REPORT_FAILURE});

export const getTournamentDocuments = (params) => async dispatch => {
    dispatch(getTournamentDocumentsInit());
    try {
        const response = await http.get(APIs.DOCUMENTS, {params});
        dispatch(getTournamentDocumentsSuccess(response.data));
        return response.data;
    } catch (e) {
        dispatch(getTournamentDocumentsFailure());
    }
};

export const resetDocuments = () => dispatch => dispatch(getTournamentDocumentsSuccess([]));
const getTournamentDocumentsInit = () => ({type: ACTIONS_TYPES.GET_DOCUMENTS_INIT});
const getTournamentDocumentsSuccess = (payload) => ({type: ACTIONS_TYPES.GET_DOCUMENTS_SUCCESS, payload});
const getTournamentDocumentsFailure = () => ({type: ACTIONS_TYPES.GET_DOCUMENTS_FAILURE});

export const uploadTournamentDocument = (body) => async dispatch => {
    dispatch(uploadTournamentDocumentInit());
    try {
        await http.post(APIs.DOCUMENTS, body);
        dispatch(uploadTournamentDocumentSuccess());
    } catch (e) {
        dispatch(uploadTournamentDocumentFailure());
    }
};

const uploadTournamentDocumentInit = () => ({type: ACTIONS_TYPES.UPLOAD_DOCUMENTS_INIT});
const uploadTournamentDocumentSuccess = (payload) => ({type: ACTIONS_TYPES.UPLOAD_DOCUMENTS_SUCCESS, payload});
const uploadTournamentDocumentFailure = () => ({type: ACTIONS_TYPES.UPLOAD_DOCUMENTS_FAILURE});

export const deleteFile = (file) => async dispatch => {
    try {
        await http.delete(`${APIs.DOCUMENTS}/${file.documentID}`);
    } catch (e) {
    }
};
