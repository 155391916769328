import {
    PATH_URLS,
    PERMISSION_TYPES as TYPE,
    PERMISSIONS,
    ROLE_TYPES,
    SIDEBAR_ITEM_KEYS,
    ADMIN_MENU_CATEGORY
} from "constants/index";
import Home from 'pages/home';
import Contact from 'pages/contact';
import Ranking from 'pages/ranking';
import EventDetailsPage from "pages/eventDetails";
import EventRegister from "pages/eventDetails/register";
import LoginPage from "pages/login";
import RegisterPage from "pages/register";
import ConfirmRegister from "pages/register/confirmRegister";
import ChangePassword from "pages/changePassword";
import ForgotPassword from "pages/forgotPassword";
import AdminProfile from "pages/profile_admin";
import UserProfile from "pages/profile_user";
import Admin_Tournaments from "pages/admin_pages/tournaments";
import {PageNotFound} from "components/PageNotFound";
import {checkPermission, checkRole, checkIsSuperAdmin} from "utils";
import EventBasicDetails from 'pages/eventDetails/basicDetails';
import EventDocuments from 'pages/eventDetails/documents';
import EventPlayers from 'pages/eventDetails/teams';
import EventGames from 'pages/eventDetails/games';
import EventRankings from 'pages/eventDetails/ranking';
import EventContact from 'pages/eventDetails/contact';
import Admin_Schedules from "pages/admin_pages/schedule";
import Admin_Games from "pages/admin_pages/games";
import Admin_Teams from "pages/admin_pages/teams";
import Admin_AgreementFiles from "pages/admin_pages/agreementFiles";
import Admin_Cities from "pages/admin_pages/cities";
import Admin_Players from "pages/admin_pages/players";
import Admin_PlayersHistory from "pages/admin_pages/playersHistory";
import Admin_Sponsors from "pages/admin_pages/sponsors";
import Admin_TshirtSizes from "pages/admin_pages/tshirtSizes";
import Admin_Rankings from "pages/admin_pages/rankings";
import Admin_Reports from "pages/admin_pages/reports";
import Admin_Users from "pages/admin_pages/users";

const isManagerOrAdmin = checkRole(ROLE_TYPES.SUPER_ADMIN) || checkRole(ROLE_TYPES.ADMIN) || checkRole(ROLE_TYPES.MANAGER);

export const routes = [
    {
        path: PATH_URLS.HOME,
        exact: true,
        component: Home,
        isAvailable: () => true
        // sidebar: 'Home Page',
        // showInMenu: true
    },
    {
        path: PATH_URLS.RANKING,
        component: Ranking,
        isAvailable: () => true
        // sidebar: 'Home Page',
        // showInMenu: true
    },
    {
        path: PATH_URLS.CONTACT,
        component: Contact,
        isAvailable: () => true
        // sidebar: 'Home Page',
        // showInMenu: true
    },
    {
        path: PATH_URLS.ABOUT,
        component: 'about',
        isAvailable: () => true
        // sidebar: 'Home Page',
        // showInMenu: true
    },
    {
        // path: `${PATH_URLS.EVENT}/:id`,
        path: `${PATH_URLS.EVENT}/:id`,
        component: EventDetailsPage,
        // exact: true,
        isAvailable: () => true
        // sidebar: 'Home Page',
        // showInMenu: true
    },
    {
        path: PATH_URLS.ADMIN_PROFILE,
        component: AdminProfile,
        exact: true,
        isAvailable: (isLoggedIn) => isLoggedIn && isManagerOrAdmin
        // sidebar: 'Test Page'
    },
    {
        path: PATH_URLS.USER_PROFILE,
        component: UserProfile,
        exact: true,
        isAvailable: (isLoggedIn) => isLoggedIn
        // sidebar: 'Test Page'
    },
    {
        path: `${PATH_URLS.ADMIN_PROFILE}${PATH_URLS.ADMIN_TOURNAMENTS}`,
        component: Admin_Tournaments,
        isAvailable: (isLoggedIn) => isLoggedIn && checkPermission(PERMISSIONS.Tournaments, TYPE.ADMIN),
        menu: ADMIN_MENU_CATEGORY.TOURNAMENTS,
        pageTitle: "Tournaments",
        showInMenu: true
    },
    {
        path: `${PATH_URLS.ADMIN_PROFILE}${PATH_URLS.ADMIN_SCHEDULES}`,
        component: Admin_Schedules,
        isAvailable: (isLoggedIn) => isLoggedIn && checkPermission(PERMISSIONS.Schedules, TYPE.ADMIN),
        menu: ADMIN_MENU_CATEGORY.TOURNAMENTS,
        pageTitle: "Schedules",
        showInMenu: true
    },
    {
        path: `${PATH_URLS.ADMIN_PROFILE}${PATH_URLS.ADMIN_TEAMS}`,
        component: Admin_Teams,
        isAvailable: (isLoggedIn) => isLoggedIn && checkPermission(PERMISSIONS.Teams, TYPE.ADMIN),
        menu: ADMIN_MENU_CATEGORY.TOURNAMENTS,
        pageTitle: "Teams",
        showInMenu: true
    },
    {
        path: `${PATH_URLS.ADMIN_PROFILE}${PATH_URLS.ADMIN_GAMES}`,
        component: Admin_Games,
        isAvailable: (isLoggedIn) => isLoggedIn && checkPermission(PERMISSIONS.Games, TYPE.ADMIN),
        menu: ADMIN_MENU_CATEGORY.TOURNAMENTS,
        pageTitle: "Games",
        showInMenu: true
    },
    {
        path: `${PATH_URLS.ADMIN_PROFILE}${PATH_URLS.ADMIN_TOURNAMENT_RANKINGS}`,
        component: Admin_Rankings,
        isAvailable: (isLoggedIn) => isLoggedIn && checkPermission(PERMISSIONS.TournamentRankings, TYPE.ADMIN),
        menu: ADMIN_MENU_CATEGORY.TOURNAMENTS,
        pageTitle: "Rankings",
        showInMenu: true
    },
    {
        path: `${PATH_URLS.ADMIN_PROFILE}${PATH_URLS.ADMIN_SPONSORS}`,
        component: Admin_Sponsors,
        isAvailable: (isLoggedIn) => isLoggedIn && checkPermission(PERMISSIONS.Sponsors, TYPE.ADMIN),
        menu: ADMIN_MENU_CATEGORY.TOURNAMENTS,
        pageTitle: "Sponsors",
        showInMenu: true
    },
    {
        path: `${PATH_URLS.ADMIN_PROFILE}${PATH_URLS.ADMIN_REPORTS}`,
        component: Admin_Reports,
        // isAvailable: (isLoggedIn) => isLoggedIn && checkPermission(PERMISSIONS.Sponsors, TYPE.ADMIN),
        isAvailable: (isLoggedIn) => !!isLoggedIn,
        menu: ADMIN_MENU_CATEGORY.TOURNAMENTS,
        pageTitle: "Reports",
        showInMenu: true
    },
    {
        path: `${PATH_URLS.ADMIN_PROFILE}${PATH_URLS.ADMIN_PLAYERS}`,
        component: Admin_Players,
        isAvailable: (isLoggedIn) => isLoggedIn && checkPermission(PERMISSIONS.Players, TYPE.ADMIN),
        menu: ADMIN_MENU_CATEGORY.PLAYERS,
        pageTitle: "Players",
        showInMenu: true
    },
    {
        path: `${PATH_URLS.ADMIN_PROFILE}${PATH_URLS.ADMIN_PLAYERS_HISTORY}/:id`,
        component: Admin_PlayersHistory,
        isAvailable: (isLoggedIn) => isLoggedIn && checkPermission(PERMISSIONS.PlayerHistories, TYPE.ADMIN),
        menu: ADMIN_MENU_CATEGORY.PLAYERS,
        pageTitle: "Players History",
        showInMenu: false,
        exact: false
    },
    {
        path: `${PATH_URLS.ADMIN_PROFILE}${PATH_URLS.ADMIN_AGREEMENT_FILES}`,
        component: Admin_AgreementFiles,
        isAvailable: (isLoggedIn) => isLoggedIn && checkPermission(PERMISSIONS.Agreements, TYPE.ADMIN),
        menu: ADMIN_MENU_CATEGORY.GENERAL,
        pageTitle: "Agreement Files",
        showInMenu: true
    },
    {
        path: `${PATH_URLS.ADMIN_PROFILE}${PATH_URLS.ADMIN_CITIES}`,
        component: Admin_Cities,
        isAvailable: (isLoggedIn) => isLoggedIn && checkPermission(PERMISSIONS.Cities, TYPE.ADMIN),
        menu: ADMIN_MENU_CATEGORY.GENERAL,
        pageTitle: "Cities",
        showInMenu: true
    },
    {
        path: `${PATH_URLS.ADMIN_PROFILE}${PATH_URLS.ADMIN_TSHIRT_SIZES}`,
        component: Admin_TshirtSizes,
        isAvailable: (isLoggedIn) => isLoggedIn && checkPermission(PERMISSIONS.Sizes, TYPE.ADMIN),
        menu: ADMIN_MENU_CATEGORY.GENERAL,
        pageTitle: "T-Shirt Sizes",
        showInMenu: true
    },
    {
        path: `${PATH_URLS.ADMIN_PROFILE}${PATH_URLS.ADMIN_USERS}`,
        component: Admin_Users,
        isAvailable: (isLoggedIn) => isLoggedIn && checkRole(ROLE_TYPES.SUPER_ADMIN),
        menu: ADMIN_MENU_CATEGORY.GENERAL,
        pageTitle: "Users",
        showInMenu: checkRole(ROLE_TYPES.SUPER_ADMIN)
    },
    {
        path: PATH_URLS.LOGIN,
        component: LoginPage,
        isAvailable: (isLoggedIn) => !isLoggedIn
    },
    {
        path: PATH_URLS.REGISTER,
        component: RegisterPage,
        isAvailable: (isLoggedIn) => !isLoggedIn
        // sidebar: 'Test Page'
    },
    {
        path: PATH_URLS.CONFIRM_REGISTER,
        component: ConfirmRegister,
        exact:false,
        isAvailable: (isLoggedIn) => !isLoggedIn
        // sidebar: 'Test Page'
    },
    {
        path: PATH_URLS.CHANGE_PASSWORD,
        component: ChangePassword,
        isAvailable: () => true
        // sidebar: 'Test Page'
    },
    {
        path: PATH_URLS.FORGOT_PASSWORD,
        component: ForgotPassword,
        isAvailable: () => true
        // sidebar: 'Test Page'
    },
    {
        path: '*',
        component: PageNotFound,
        isAvailable: () => true
    }
];

export const eventRoutes = [
    {
        path: SIDEBAR_ITEM_KEYS.DETAILS,
        component: EventBasicDetails,
        exact: true,
        pageTitle: 'Details',
        showInMenu: true,
        isAvailable: () => true,
    },
    {
        path: PATH_URLS.LOGIN,
        component: LoginPage,
        exact: false,
        pageTitle: 'Register',
        showInMenu: true,
        isAvailable: (isLoggedIn) => !isLoggedIn,
    },
    {
        path: SIDEBAR_ITEM_KEYS.REGISTER,
        component: EventRegister,
        exact: false,
        pageTitle: 'Register',
        showInMenu: true,
        isAvailable: (isLoggedIn) => isLoggedIn,
    },
    {
        path: SIDEBAR_ITEM_KEYS.PLAYERS,
        component: EventPlayers,
        exact: false,
        pageTitle: 'Teams',
        showInMenu: true,
        isAvailable: () => true,
    },
    {
        path: SIDEBAR_ITEM_KEYS.GAMES,
        component: EventGames,
        exact: false,
        pageTitle: 'Games',
        showInMenu: true,
        isAvailable: () => true,
    },
    {
        path: SIDEBAR_ITEM_KEYS.RANKING,
        component: EventRankings,
        exact: false,
        pageTitle: 'Results',
        showInMenu: true,
        isAvailable: () => true,
    },
    // {
    //     path: SIDEBAR_ITEM_KEYS.SPONSORS,
    //     component: EventSponsors,
    //     exact: false,
    //     // isAvailable: () => true
    //     pageTitle: 'Sponsors',
    //     showInMenu: false
    // },
    {
        path: SIDEBAR_ITEM_KEYS.DOCUMENTS,
        component: EventDocuments,
        exact: false,
        pageTitle: 'Files',
        showInMenu: true,
        isAvailable: () => true,
    },
    {
        path: SIDEBAR_ITEM_KEYS.CONTACT,
        component: EventContact,
        exact: false,
        // isAvailable: () => true
        pageTitle: 'Contact',
        showInMenu: true,
        isAvailable: () => true,
    }
];
